import config from '../config'

import DateTime from './DateTime.js'

export default {
    mixins: [DateTime],
    data() {
        return {
            orderStatuses: [
                {
                    text: 'Новый',
                    color: '#9c27b0', // purple
                    icon: 'mdi-flash',
                },
                {
                    text: 'Ожидание курьера',
                    color: '#ff9800', // orange
                    icon: 'mdi-run-fast',
                },
                {
                    text: 'В пути к гостю',
                    color: '#2196f3', // blue
                    icon: 'mdi-cube-send',
                },
                {
                    text: 'Доставлен',
                    color: '#4caf50', // green
                    icon: 'mdi-check-bold',
                },
                {
                    text: 'Создается',
                    color: '#607d8b', // blue-grey
                    icon: 'mdi-account-question',
                },
                {
                    text: 'Назначен',
                    color: '#f44336', // red
                    icon: 'mdi-arrow-right-bold',
                },
            ],
        }
    },
    created: function() {
    },
    methods: {
        orderBodyText(order) {
            if (!order.start_addr) {
                order.start_addr = '---'
            }
            if (!order.finish_addr) {
                order.finish_addr = '---'
            }
            let res = `🏪 Откуда: <b>${order.start_addr.replace('\n', ' ')}</b><br>`
            res += `🏡 Куда: <b>${order.finish_addr.replace('\n', ' ')}</b><br>`
            res += `🎫 Гость: <b>${order.guest.replace('\n', ' ')}</b><br>`
            res += `🧾 Способ оплаты: <b>${order.pay.replace('\n', ' ')}</b><br>`
            res += `💵 Сумма заказа: <b>${order.pay_cost.toString().replace('\n', ' ')}</b><br>`
            res += `${ order.mode }: забрать до <b>${ this.timeToString(order.date_ready, true) }</b>, доставить к <b>${ this.timeToString(order.date_delivery, true) }</b><br>`
            res += order.descr == '' ? '' : `💬 Комментарий: <b>${order.descr.replace('\n', ' ')}</b><br>`
            res += `🕓 Создан: <b>${this.timeToString(order.date)}</b><br>`
            return res
        },

        orderFullText(order) {
            let text = ''
            // console.log('orderFullText order', order)
            let courierStr = 'нет'
            let status = 'не обработан ❗️'
            courierStr = order.courier_name
            if (order.status == config.order.status.way_point) {
                status = 'в пути к заведению'
                // status = 'в пути к заведению (1.3 км)'
                // let addr = 'адрес не определен'
                // courierStr += ` (${addr})`
            } else if (order.status == config.order.status.way_guest) {
                status = 'в пути к гостю'
                // status = 'в пути к гостю (0.4 км)'
                // let addr = 'адрес не определен'
                // courierStr += ` (${addr})`
            } else if (order.status == config.order.status.passed) {
                status = 'назначен курьеру (ожидание ответа)'
            } else if (order.status == config.order.status.complete) {
                status = 'доставлен ✅'
            }

            text += `📦 <b>${order.name}</b> <span class="order-id">${order.id}</span><br>`
            text += this.orderBodyText(order)
            if ((order.status == config.order.status.complete || order.status == config.order.status.way_point || order.status == config.order.status.way_guest || order.status == config.order.status.passed) && order.date_accept) {
                text += `🕓 Отправлен: <b>${this.timeToString(order.date_accept)}</b><br>`
            }
            if ((order.status == config.order.status.complete || order.status == config.order.status.way_point || order.status == config.order.status.way_guest || order.status == config.order.status.passed) && order.date_accept && order.date_take) {
                text += `🚩 Забран: <b>${this.timeToString(order.date_take)}</b><br>`
            }
            if (order.status == config.order.status.complete && order.date_complete) {
                text += `🏁 Доставлен: <b>${this.timeToString(order.date_complete)}</b><br>`
            }
            text += `🔅 Статус: <b>`
            switch (order.status) {
                case config.order.status.new: {
                    text += 'не обработан ❗️'
                    break
                }
                case config.order.status.way_point: {
                    text += 'в пути к заведению 🛵'
                    break
                }
                case config.order.status.way_guest: {
                    text += 'в пути к гостю 🛵'
                    break
                }
                case config.order.status.complete: {
                    text += 'доставлен ✅'
                    break
                }
                case config.order.status.forming: {
                    text += 'формируется ⏳'
                    break
                }
                case config.order.status.passed: {
                    text += 'назначен курьеру ⏳'
                    break
                }
            }
            text += `</b><br>`
            if (order.status == config.order.status.complete || order.status == config.order.status.way_point || order.status == config.order.status.way_guest || order.status == config.order.status.passed) {
                text += `🛵 Курьер: <b>${courierStr}</b><br>`
            }
            return text
        },

        orderContent(order) {
            let html = this.orderFullText(order)
            return html
        },

        orderStatusStyle(order) {
            return {
                backgroundColor: this.orderStatuses[order.status].color,
            }
        },
    }
}